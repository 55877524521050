import type { VueIslandConfig } from "@solvari/common-fe/helpers";

import { initVueIslands } from "@solvari/common-fe/helpers";

import { initPrimeVueThemed } from "@solvari/components";
import { getEnv } from "@solvari/utils";

function initPlatformVueIslands() {
  const components: VueIslandConfig = {
    brochureForm: () =>
      import("@/platform/components/brochure-form/BrochureForm.vue"),
    productSearch: () =>
      import("@/platform/components/homepage/product-search/productSearch.ts"),
    explanationAnimation: () =>
      import(
        "@/platform/components/explanation/explanation-animation/ExplanationAnimation.vue"
      ),
    reviewForm: () => import("@/platform/components/reviewForm.ts"),
    sBlogList: () => import("@/platform/components/blog/SBlogList.vue"),
    sProSuccessBlogList: () =>
      import("@/platform/components/blog/SProSuccessBlogList.vue"),
    sProKnowledgeBlogList: () =>
      import("@/platform/components/blog/SProKnowledgeBlogList.vue"),
    sAffiliateForm: () =>
      import("@/affiliates/components/registration-form/SAffiliateForm.vue"),
    companyWallProductSelect: () =>
      import(
        "@/platform/components/company-wall-details/cta/ProductSelect.vue"
      ),
    proProductSelect: () =>
      import("@/platform/components/intro/ProProductSelect.vue"),
  };

  const pages: VueIslandConfig = {
    companyWall: () => import("@/platform/pages/company-wall/CompanyWall.vue"),
    searchPage: () => import("@/platform/pages/search/SearchPage.vue"),
  };

  initVueIslands(
    { ...components, ...pages },
    (app) => {
      initPrimeVueThemed(app);
    },
    getEnv().config.localeCode,
  );
}

export { initPlatformVueIslands };
